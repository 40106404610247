<template>
  <div class="TemplateList">
    <div class="page-container">
      <div class="section">
        <!--标题栏-->
        <div class="control-box">
          <div class="cb-left">
            <h1 style="font-size: 20px">模板</h1>
          </div>
        </div>

        <!--搜索栏-->
        <div class="control-box" style="border-bottom: 0;margin-bottom: 0;padding-bottom: 0;">
          <div class="cb-left">
            <el-form ref="form" inline class="control-item" :model="form" label-width="auto">
              <el-form-item label-width='0'>
                <el-input
                    v-model.trim="form.name"
                    clearable
                    placeholder="名称"
                    style="width: 200px;"
                    @clear="form.name = undefined"
                    @keyup.enter.native="handleFilter"
                />
              </el-form-item>

              <el-form-item label="设备类型：">
                <el-cascader
                    clearable
                    placeholder="请选择"
                    :options="option.deviceTypes"
                    :props="{ expandTrigger: 'hover' }"
                    @change="handleChange"
                />
              </el-form-item>

              <el-form-item label="保养类型：">
                <el-select
                    v-model="type0"
                    clearable
                    style="width: 150px"
                    @clear="type0 = undefined"
                >
                  <el-option
                      v-for="item in option.maintainTypes"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                  />
                </el-select>
              </el-form-item>

              <el-form-item label="模板类型：">
                <el-select
                    v-model="templateType0"
                    clearable
                    style="width: 150px"
                    @clear="templateType0 = undefined"
                >
                  <el-option
                      v-for="item in option.templateTypes"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                  />
                </el-select>
              </el-form-item>

              <el-form-item>
                <el-button
                    class="control-item"
                    icon="el-icon-search"
                    type="primary"
                    @click="handleFilter"
                >
                  搜索
                </el-button>
              </el-form-item>
            </el-form>
          </div>
          <!--右-->
          <div v-if="managePermission" class="cb-right" style="white-space: nowrap">
            <el-button type="primary" icon="el-icon-plus" @click="createTemplate">
              添加
            </el-button>

            <el-button type="primary" icon="el-icon-plus" @click="daoru = true">
              导入Excel
            </el-button>
          </div>
        </div>

        <div class="table-box">
          <el-table
              v-loading="listLoading"
              :data="templateList"
              element-loading-text="Loading"
              border
              fit
              highlight-current-row
          >
            <!--序号-->
            <el-table-column label="序号" align="center" width="60">
              <template slot-scope="scope">
                {{ scope.$index + 1 }}
              </template>
            </el-table-column>

            <el-table-column label="名称" align="center">
              <template slot-scope="scope">
                {{ scope.row.name }}
              </template>
            </el-table-column>
            <el-table-column label="印刷类型" align="center">
              <template slot-scope="scope">
                {{ scope.row.printingType }}
              </template>
            </el-table-column>

            <el-table-column label="设备类型" align="center">
              <template slot-scope="scope">
                {{ scope.row.deviceType }}
              </template>
            </el-table-column>

            <el-table-column label="创建时间" align="center">
              <template slot-scope="scope">
                {{ scope.row.time }}
              </template>
            </el-table-column>

            <el-table-column label="保养类型" align="center">
              <template slot-scope="scope">
                {{ scope.row.templateType }}
              </template>
            </el-table-column>

            <el-table-column label="模板类型" align="center">
              <template slot-scope="scope">
                {{ scope.row.type }}
              </template>
            </el-table-column>

            <!--操作-->
            <el-table-column label="操作" header-align='center' align="left" width="370">
              <template slot-scope="scope">
                <el-button
                    type="primary"
                    size="mini"
                    @click="showTemplateDetail(scope.row)"
                >
                  查看
                </el-button>

                <el-button
                    v-if="scope.row.type === '个人模板' && managePermission"
                    type="primary"
                    size="mini"
                    @click="editTemplateDetail(scope.row)"
                >
                  编辑
                </el-button>

                <el-button
                    v-if="managePermission"
                    type="primary"
                    size="mini"
                    @click="copyTemplate(scope.row)"
                >
                  复制
                </el-button>

                <el-button
                    v-if="managePermission"
                    type="primary"
                    size="mini"
                    @click="exportTemplateDetail(scope.row)"
                >
                  导出
                </el-button>

                <el-button
                    v-if="scope.row.type === '个人模板' && managePermission"
                    type="primary"
                    plain
                    size="mini"
                    icon="el-icon-delete"
                    @click="deleteTemplate(scope.row)"
                >
                  删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <!--分页-->
        <div class="pagination-box">
          <el-pagination
              :page-sizes="[10, 20, 30, 40]"
              :page-size="form.count"
              :total="totalCount"
              :current-page="form.page + 1"
              layout="total, sizes, prev, pager, next, jumper"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
          />
        </div>
      </div>
    </div>

    <!--复制弹出框-->
    <el-dialog
        v-if="showCopyTemplateDialog"
        :visible.sync="showCopyTemplateDialog"
        title="复制"
        :modal-append-to-body="false"
        :append-to-body="false"
        :close-on-click-modal="false"
        @close="closeCopyDialog"
    >
      <el-form
          ref="copyEntity"
          :model="copyEntity"
          :rules="rules"
          label-width="100px"
      >
        <el-form-item label="模板名称" prop="name">
          <el-input
              v-model.trim="copyEntity.name"
              clearable
              placeholder="模板名称"
              size="medium"
              @clear="copyEntity.name = ''"
          />
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button type="primary" :loading="copyConifmBtnLoading" @click="copyConifmBtn">
          确认
        </el-button>
      </div>
    </el-dialog>

    <!--导入弹出框-->
    <el-dialog
        v-if="daoru"
        :visible.sync="daoru"
        title="导入Excel"
        :modal-append-to-body="false"
        :append-to-body="false"
        style="width: 1500px"
        @close="daoru = false"
    >
      <div>
        <div id="upload" @click="postBut">
          <i class="el-icon-upload" />
          <div class="upload-text">请选择要上传的文件</div>
        </div>
        <div ref="tip" />
        <input
            ref="inputName"
            type="file"
            style="visibility: hidden;"
            multiple="multiple"
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            @change="handleOk($event)"
        />
        <p ref="hel" />
      </div>
      <el-link target="_blank" :href="muBanUrl" :underline="false">
        <el-button type="primary" size="small" icon="el-icon-download">下载导入模板</el-button>
      </el-link>
    </el-dialog>
  </div>
</template>

<script>
import API from '@/api';
import { manageOauth } from '@/utils/validate';
import convert from '@/utils/convert';
import valid from '@/utils/validator';

export default {
  name: 'TemplateList',
  data() {
    return {
      buttons: '',
      parmas: [],
      muBanUrl: '',
      listLoading: false,
      templateList: [],
      copyConifmBtnLoading: false,
      showCopyTemplateDialog: false,
      fileTemp: [],
      type0: undefined, // 保养类型
      templateType0: undefined, // 模板类型
      // 查询
      form: {
        name: undefined, // 模板名称
        printType: undefined, // 印刷类型
        deviceType: undefined, // 设备类型
        type: -1, // 保养类型
        templateType: -1, // 模板类型
        page: 0,
        count: 10,
      },
      // 下拉选项
      option: {
        // 设备类型
        deviceTypes: [],
        // 保养类型
        maintainTypes: [
          // { name: '全部', id: -1 },
          { name: '日保养', id: 1 },
          { name: '周保养', id: 2 },
          { name: '月保养', id: 3 },
          { name: '季度保养', id: 4 },
          { name: '半年度保养', id: 5 },
          { name: '年度保养', id: 6 },
        ],
        // 模板类型
        templateTypes: [
          // { name: '全部', id: -1 },
          { name: '个人模板', id: 1 },
          { name: '系统模板', id: 2 },
        ],
      },
      // 表单验证
      rules: {
        name: [
          {
            required: true,
            trigger: 'blur',
            validator: (rule, value, callback) => {
              valid.checkNull(rule, value, callback, '请输入模板名称', false);
              valid.checkByRegex(rule, value, callback, /^.{1,30}$/, '请输入1-30字的模板名称');
            },
          },
        ],
      },
      da: [],
      daoru: false,
      totalCount: 0,
      copyEntity: {
        name: '',
        templateId: '',
      },
    };
  },
  computed: {
    managePermission: {
      get() {
        return manageOauth('device_operate', this.buttons);
      },
    },
  },
  created() {
    // 团队管理，设备运维埋点数据接口
    API.userLog({ code: 'webTemplateList' }).then(() => {});

    if (this.$store.getters.userInfo.buttons) {
      this.buttons = this.$store.getters.userInfo.buttons;
    } else {
      this.buttons = sessionStorage.getItem('buttons');
    }
    window.addEventListener('beforeunload', () => {
      sessionStorage.setItem('buttons', JSON.stringify(this.$store.getters.userInfo.buttons));
    });
    // 获取设备类型
    this.getDeviceList();
    // 获取模板列表
    this.getTemplateList();
  },
  methods: {
    // 搜索
    handleFilter() {
      // -1是全部
      this.form.type = this.type0 || -1; // 保养类型
      this.form.templateType = this.templateType0 || -1; // 模板类型
      this.form.count = 10;
      this.form.page = 0;
      this.getTemplateList();
    },
    postBut() {
      // 模拟上传功能
      const post = this.$refs.inputName;
      post.click();
    },
    // 导入
    handleOk(event) {
      const that = this;
      const formData = new FormData();
      formData.append('excel', event.target.files[0]);
      API.importTemplate(formData)
          .then((response) => {
            // console.log(response)
            // 由于与老的设备运维用的同一个接口，但是拦截封装的不一样，所以这里需要加个判断
            if (response.message.success) {
              that.daoru = false;
              that.getTemplateList(); // 获取模板列表
            }
            that.$message({
              message: response.message.message,
              showClose: true,
              type: response.message.success ? 'success' : 'error',
            });
            that.$refs.inputName.value = '';
          })
          .catch(() => {
            that.$refs.inputName.value = '';
          });
    },
    // 导出
    exportTemplateDetail(val) {
      // console.log(val)
      API.exportTemplate(val.id)
          .then((response) => {
            // console.log(response.message.data)
            window.open(response.message.data);
            this.$message({
              message: response.message.message,
              type: 'success',
            });
          })
          .catch((error) => {
            console.log(error);
          });
    },
    // 设备类型改变时
    handleChange(val) {
      // console.log(val)
      this.form.printType = val[0];
      this.form.deviceType = val[1];
    },
    // 获取设备列表
    getDeviceList() {
      API.getDeviceConfigList()
          .then((response) => {
            // console.log(response)
            // 转换数据
            this.option.deviceTypes = convert.arr2cascader_deviceconfig(response.message.data);
          });
    },
    // 获取模板列表
    getTemplateList() {
      API.getDeviceTemplateList(this.form).then((response) => {
        this.templateList = response.message.data.list.list;
        this.muBanUrl = response.message.data.list.url;
        this.totalCount = response.message.data.totalCount;
        for (const item of this.templateList) {
          if (item.type === '0') {
            item.type = '系统模板';
          } else {
            item.type = '个人模板';
          }
        }
      });
    },
    // 编辑
    editTemplateDetail(val) {
      this.$router.push({ path: 'templateDetail', query: { id: val.id, isEdit: true } });
    },
    // 查看
    showTemplateDetail(val) {
      this.$router.push({ path: 'templateDetail', query: { id: val.id } });
    },
    // 复制
    copyTemplate(val) {
      this.copyEntity.name = '';
      this.showCopyTemplateDialog = true;
      this.copyEntity.templateId = val.id;
    },
    // 关闭复制弹出框
    closeCopyDialog() {
      this.showCopyTemplateDialog = false;
    },
    // 复制确认
    copyConifmBtn() {
      this.$refs.copyEntity.validate((v) => {
        if (v) {
          this.copyConifmBtnLoading = true;
          API.copyTemplate({
            name: this.copyEntity.name,
            templateId: this.copyEntity.templateId,
          })
              .then((response) => {
                if (response.message.success) {
                  this.copyConifmBtnLoading = false;
                  this.showCopyTemplateDialog = false;
                  this.getTemplateList();
                  this.copyEntity.name = '';
                  this.$message({
                    message: response.message.message,
                    showClose: true,
                    type: 'success',
                  });
                } else {
                  this.copyConifmBtnLoading = false;
                  this.$message({
                    message: response.message.message,
                    showClose: true,
                    type: 'error',
                  });
                }
              })
              .catch(() => {
                this.copyConifmBtnLoading = false;
              });
        }
      });
    },
    // 删除
    deleteTemplate(val) {
      this.$confirm('是否确认删除?', '提示', {
        closeOnClickModal: false,
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning',
      })
          .then(() => {
            API.deleteTemplate({
              templateId: val.id,
            })
                .then((response) => {
                  this.getTemplateList();
                  this.$message({
                    message: response.message.message,
                    showClose: true,
                    type: 'success',
                  });
                })
                .catch(() => {});
          })
          .catch(() => {});
    },
    // 分页器
    handleSizeChange(val) {
      this.form.count = val;
      this.getTemplateList();
    },
    // 分页器
    handleCurrentChange(val) {
      this.form.page = val - 1;
      this.getTemplateList();
    },
    // 去添加 managePermission
    createTemplate() {
      this.$router.push({ path: 'templateDetail', query: { id: 'NEW' } });
    },
  },
};
</script>

<style lang="scss" scoped>
#upload {
  position: relative;
  margin: 15px 0;
  width: 300px;
  height: 150px;
  background: rgba(134, 132, 132, 0.5);
  border-radius: 5px;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.el-icon-upload {
  display: block;
  width: 50px;
  height: 50px;
  font-size: 50px;
  margin: 10px 50px;
}
.upload-text {
  width:200px;
  text-align: center;
}
.control-box {
  .el-form-item {
    margin-bottom: 22px;
    //margin-right: 15px;
  }
}
</style>
